import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/services/auth/auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppRoutes } from 'src/app/app-routes';
import { UserRole } from 'src/app/core';
import { StringHelper } from 'src/app/core/helpers/string-helper';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})

@UntilDestroy()
export class HeaderComponent {
  public appRoutes = AppRoutes;
  public readonly roles = UserRole;

  constructor(private _auth: AuthService) {}

  get activeUser() {
    return this._auth.storedUser;
  }

  get userName() {
    return `${
      StringHelper.firstLetterToUpperCase(this.activeUser.firstName) || ''
    } ${StringHelper.firstLetterToUpperCase(this.activeUser.lastName) || ''}`;
  }

  get routing() {
    return this.activeUser.role === this.roles.CLIENT_ADMIN
      ? '/admin'
      : '/client';
  }
}
