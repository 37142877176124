import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.dev';
import { AuthResponse } from '../../interfaces/auth.interfaces';
import { tap, shareReplay, catchError, throwError } from 'rxjs';

import { Router } from '@angular/router';
import { UserModel } from '../../interfaces/user.model';
import { AppRoutes } from 'src/app/app-routes';

export const USER_DATA = 'userData';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private router: Router,
  ) {}

  signIn(email: string, password: string) {
    return this.http
      .post<AuthResponse>(`${environment.host}auth/local`, {
        identifier: email,
        password: password,
      })
      .pipe(
        tap((res) => this.handleUser(res)),
        shareReplay(),
        catchError((error) => {
          return throwError(error.error.message);
        }),
      );
  }

  handleUser(response: AuthResponse) {
    const user = new UserModel(
      response.user.first_name,
      response.user.last_name,
      response.user.email,
      response.user._id,
      response.jwt,
      response.user.role.type,
      response.user.role.name,
      response.user.blocked,
    );

    localStorage.setItem(USER_DATA, JSON.stringify(user));
  }

  public get storedUser() {
    return JSON.parse(localStorage.getItem(USER_DATA)!);
  }

  public isLoggedIn(): boolean {
    const user = localStorage.getItem(USER_DATA);
    if (user) {
      return true;
    }

    return false;
  }

  public forgotPassword(email: Object) {
    return this.http.post(`${environment.host}auth/forgot`, email);
  }

  public resetPassword(passwords: Object, token: string) {
    const headers = { Authorization: `Bearer ${token}` };
    return this.http.post(`${environment.host}auth/reset`, passwords, {
      headers,
    });
  }

  public logout() {
    localStorage.removeItem(USER_DATA);
    this.router.navigate([`/${AppRoutes.AUTH}`]);
  }
}
