<section class="confirmation auth-bg">
  <img class="img-top" src="/assets/auth/confirmation/1.svg" alt="image" />
  <div class="confirmation-container">
    <div class="confirmation-wrapper">
      <img
        class="img-logo"
        src="/assets/auth/workplace-hover.svg"
        alt="logo-image"
      />
      <div class="confirmation-title">
        <h2>{{ "auth.titleConfirmation" | translate }}</h2>
        <p>
          {{ "auth.clientSignUp.descriptionConfirmation" | translate }}
        </p>
      </div>
      <form [formGroup]="form">
        <nz-form-item class="inline-field">
          <nz-form-label nzFor="firstName"
            >{{ "forms.addClient.firstName" | translate
            }}<span>*</span></nz-form-label
          >
          <app-form-field>
            <input
              nz-input
              oninput="this.value ? this.value : this.value.trim()"
              formControlName="first_name"
              placeholder="{{ 'forms.addClient.enterFirstName' | translate }}"
              class="form-input"
              appFormInput
              id="firstName"
            />
          </app-form-field>
        </nz-form-item>
        <nz-form-item class="inline-field">
          <nz-form-label nzFor="lastName"
            >{{ "forms.addClient.lastName" | translate
            }}<span>*</span></nz-form-label
          >
          <app-form-field>
            <input
              nz-input
              oninput="this.value ? this.value : this.value.trim()"
              formControlName="last_name"
              placeholder="{{ 'forms.addClient.enterLastName' | translate }}"
              class="form-input"
              appFormInput
              id="lastName"
            />
          </app-form-field>
        </nz-form-item>
        <nz-form-item class="inline-field">
          <nz-form-label nzFor="Email"
            >{{ "forms.addClient.email" | translate
            }}<span>*</span></nz-form-label
          >
          <app-form-field>
            <input
              nz-input
              formControlName="email"
              placeholder="{{ 'forms.addClient.enterEmail' | translate }}"
              class="form-input"
              appFormInput
              id="email"
              [disabled]="true"
            />
          </app-form-field>
        </nz-form-item>
        <nz-form-item class="inline-field">
          <nz-form-label nzFor="phone"
            >{{ "forms.addClient.phone" | translate
            }}<span>*</span></nz-form-label
          >
          <app-form-field>
            <input
              nz-input
              oninput="this.value ? this.value : this.value.trim()"
              formControlName="phone"
              placeholder="{{ 'forms.addClient.enterNumber' | translate }}"
              class="form-input"
              appFormInput
              id="phone"
            />
          </app-form-field>
        </nz-form-item>
        <nz-form-item class="inline-field">
          <nz-form-label nzFor="password"
            >{{ "auth.password" | translate }}<span>*</span></nz-form-label
          >
          <app-form-field>
            <nz-input-group
              class="form-input-group"
              [nzSuffix]="passwordSuffix"
              [nzStatus]="passwordError"
            >
              <input
                nz-input
                formControlName="password"
                placeholder="{{ 'auth.enterPassword' | translate }}"
                oninput="this.value=this.value.trim()"
                [type]="passwordVisible ? 'text' : 'password'"
                appFormInput
                id="password"
              />
            </nz-input-group>
          </app-form-field>
        </nz-form-item>
        <nz-form-item class="inline-field">
          <nz-form-label nzFor="confirmPassword"
            >{{ "auth.confirmPassword" | translate
            }}<span>*</span></nz-form-label
          >
          <app-form-field>
            <nz-input-group
              class="form-input-group"
              [nzSuffix]="confirmPasswordSuffix"
              [nzStatus]="confirmError"
            >
              <input
                nz-input
                [type]="confirmPasswordVisible ? 'text' : 'password'"
                oninput="this.value=this.value.trim()"
                formControlName="confirm_password"
                placeholder="{{ 'auth.enterPassword' | translate }}"
                appFormInput
                id="confirmPassword"
              />
            </nz-input-group>
          </app-form-field>
        </nz-form-item>
      </form>
      <button (click)="submit()" nz-button>
        {{ "auth.buttonCreate" | translate }}
      </button>
    </div>
  </div>
  <img class="img-bottom" src="/assets/auth/confirmation/2.svg" alt="image" />
  <app-spinner *ngIf="loading"></app-spinner>
</section>

<ng-template #passwordSuffix>
  <span nz-icon (click)="passwordVisible = !passwordVisible">
    <img
      [src]="
        passwordVisible ? '/assets/icons/open.svg' : '/assets/icons/hide.svg'
      "
      alt="show-password"
    />
  </span>
</ng-template>
<ng-template #confirmPasswordSuffix>
  <span nz-icon (click)="confirmPasswordVisible = !confirmPasswordVisible">
    <img
      [src]="
        confirmPasswordVisible
          ? '/assets/icons/open.svg'
          : '/assets/icons/hide.svg'
      "
      alt="show-password"
    />
  </span>
</ng-template>

<app-message></app-message>
