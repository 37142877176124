import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormComponent } from 'src/app/_shared/classes/form-component.handler';
import { MessageService } from 'src/app/_shared/components/message';
import { eMatchValidator } from 'src/app/_shared/validators/confirm-password.validator';
import { AppRoutes } from 'src/app/app-routes';
import { AuthService } from 'src/app/core';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss'],
})
@UntilDestroy()
export class ResetComponent extends FormComponent implements OnInit {
  public loading = false;
  public passwordVisible = false;
  public confirmPasswordVisible = false;
  private token!: string;

  constructor(
    private _message: MessageService,
    private _router: Router,
    private route: ActivatedRoute,
    private _auth: AuthService,
    private _fb: FormBuilder,
  ) {
    super();
    this.route.params.subscribe((params) => {
      this.token = params['token'];
    });
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    this.createForm();
  }
  protected override createForm(): FormGroup<any> {
    return this._fb.group(
      {
        password: ['', [Validators.required]],
        confirm_password: ['', [Validators.required]],
      },
      { validator: eMatchValidator('password', 'confirm_password') },
    );
  }

  public submit() {
    if (this.form.invalid) {
      this._validateForm();
      return;
    }

    this._resetPassword();
  }

  private _resetPassword() {
    const formData = this.form.value;
    this.loading = true;

    this._auth
      .resetPassword(formData, this.token)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.loading = false;
          this._router.navigate([AppRoutes.AUTH]);
          this._message.emitSuccess(
            this._translate.instant('auth.messages.resetConfirmation'),
          );
        },
        error: (error) => {
          this.loading = false;
          this._message.emitError(error.error.message);
        },
      });
  }

  get confirmError() {
    if (
      this.form.get('confirm_password')?.invalid &&
      this.form.get('confirm_password')?.touched
    ) {
      return 'error';
    }
    return '';
  }
  get passwordError() {
    if (
      this.form.get('password')?.invalid &&
      this.form.get('password')?.touched
    ) {
      return 'error';
    }
    return '';
  }
}
