import { NgModule } from '@angular/core';
import { ResetComponent } from './reset.component';
import { CommonModule } from '@angular/common';
import { ResetRoutingModule } from './reset-router.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { FormFieldModule } from 'src/app/_shared/components/form-field';
import { MessageModule } from 'src/app/_shared/components/message';
import { SpinnerComponent } from 'src/app/_shared/components/spinner/spinner.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ResetComponent],
  imports: [
    CommonModule,
    ResetRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FormFieldModule,
    TranslateModule,
    NzFormModule,
    NzGridModule,
    NzInputModule,
    NzButtonModule,
    SpinnerComponent,
    FormFieldModule,
    MessageModule,
    NzIconModule,
    NzButtonModule,
  ],
})
export class ResetModule {}
