import { BehaviorSubject, Observable } from 'rxjs';
import { IHttpParams } from '../interfaces/http-params.interface';


const DEFAULT_HTTP_PARAMS: IHttpParams = {
};

export interface IParamsHandler<P extends IHttpParams = IHttpParams> {
  readonly params$: Observable<P>;
  patchParams(params: Partial<P>): void;
  isParamsChanged(params: Partial<P>): boolean;
}

/**
 * A class that handles HTTP parameters for pagination and filtering.
 * @template P - The type of HTTP parameters.
 */
export class ParamsHandler<P extends IHttpParams = IHttpParams>
  implements IParamsHandler<P>
{
  _params$: BehaviorSubject<P>;
  params$: Observable<P>;

  /**
   * Gets the current HTTP parameters.
   * @returns The current HTTP parameters, or null if none are set.
   */
  get params(): any | null {
    return this._params$.value;
  }

  /**
   * Returns a boolean indicating whether the current page is the first page.
   * @returns {boolean} A boolean indicating whether the current page is the first page.
   */
  get isFirstPage(): boolean {
    return this.params?.page === 1;
  }

  constructor(params: P = <P>DEFAULT_HTTP_PARAMS) {
    this._params$ = new BehaviorSubject<P>(params);
    this.params$ = this._params$.asObservable();
  }

  /**
   * Updates the current HTTP params with the provided partial params object.
   * If the new params are different from the current params, the `_params$` subject is updated.
   * @param params - The partial params object to merge with the current params.
   * @returns void
   */
  patchParams(params: Partial<P>): void {
    if (this.params && this.isParamsChanged(params)) {
      this._params$.next({ ...this.params, ...params });
    }
  }

  /**
   * Checks if any of the provided parameters have changed from their previous value.
   * @param params - The parameters to check for changes.
   * @returns True if any of the parameters have changed, false otherwise.
   */
  isParamsChanged(params: Partial<P>): boolean {
    return Object.keys(params).some(
      paramsName =>
        this.params![paramsName as keyof IHttpParams] !=
        params[paramsName as keyof IHttpParams]
    );
  }
}

/**
 * Removes null, undefined and empty array values from an object.
 * @param params - The object to shake.
 * @returns The object without null, undefined and empty array values.
 */
export function shakeParams(params: any): any {
  Object.keys(params).forEach(p => {
    if (params[p] === null || params[p] === undefined) {
      delete params[p];
    }

    if (Array.isArray(p) && !p.length) {
      delete params[p];
    }
  });

  return params;
}
