import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormComponent } from 'src/app/_shared/classes/form-component.handler';
import { MessageService } from 'src/app/_shared/components/message';
import { AppRoutes } from 'src/app/app-routes';
import { AuthService } from 'src/app/core';
import { environment } from 'src/environments/environment.dev';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss'],
})
@UntilDestroy()
export class ForgotComponent extends FormComponent implements OnInit {
  public loading = false;

  constructor(
    private _message: MessageService,
    private _router: Router,
    private _auth: AuthService,
    private _fb: FormBuilder,
  ) {
    super();
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    this.createForm();
  }
  protected override createForm(): FormGroup<any> {
    return this._fb.group({
      email: [
        '',
        [Validators.required, Validators.pattern(environment.emailPattern)],
      ],
    });
  }

  public submit() {
    if (this.form.invalid) {
      this._validateForm();
      return;
    }

    this._sendResetEmail();
  }

  private _sendResetEmail() {
    const formData = this.form.value;
    this.loading = true;

    this._auth
      .forgotPassword(formData)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.loading = false;
          this._router.navigate([AppRoutes.AUTH]);
          this._message.emitSuccess(
            this._translate.instant('auth.messages.resetLink'),
          );
        },
        error: (error) => {
          this.loading = false;
          this._message.emitError(error.error.message);
        },
      });
  }

  public onSignInClick() {
    this._router.navigate([AppRoutes.AUTH]);
  }
}
