<header class="header">
  <div class="container header__wrapper">
    <div class="header__logo">
      <img src="../../assets/header/svg/header.svg" alt="header-logo" />
    </div>

    <nav class="navigation">
      <ul>
        <li>
          <a
            [routerLink]="[routing, appRoutes.DASHBOARD]"
            routerLinkActive="active"
            >{{ "header.dashboard" | translate }}</a
          >
        </li>
        <li *ngIf="activeUser.role === roles.CLIENT_ADMIN">
          <a
            [routerLink]="['/admin', appRoutes.CLIENTS]"
            routerLinkActive="active"
            >{{ "header.clients" | translate }}</a
          >
        </li>
        <li>
          <a
            [routerLink]="[routing, appRoutes.BUILDINGS]"
            routerLinkActive="active"
            >{{ "header.buildings" | translate }}</a
          >
        </li>
        <li>
          <a
            [routerLink]="[routing, appRoutes.LIFTS]"
            routerLinkActive="active"
            >{{ "header.lifts" | translate }}</a
          >
        </li>
      </ul>
    </nav>

    <div class="header__buttons">
      <p>{{ userName }}</p>
      <app-profile-preview></app-profile-preview>
    </div>
  </div>
</header>
