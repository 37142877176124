import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';
import { EventService } from 'src/app/_shared/services/event/event.service';
import { EventData } from 'src/app/_shared/interfaces/event.class';
import { AppRoutes } from 'src/app/app-routes';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthService,
    private eventService: EventService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const userData = this.auth.storedUser;

    if (userData) {
      const cloned = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + userData._token),
      });

      return next.handle(cloned).pipe(
        catchError((error) => {
          if (
            error instanceof HttpErrorResponse &&
            !req.url.includes(`${AppRoutes.AUTH}`) &&
            error.status === 401
          ) {
            return this.handle401Error(req, next);
          }
          return throwError(() => error);
        }),
      );
    } else {
      return next.handle(req);
    }
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (this.auth.isLoggedIn()) {
      this.eventService.emit(new EventData('logout', null));
    }
    return next.handle(request);
  }
}
