import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  private error = new Subject<string>();
  public error$ = this.error.asObservable();

  private success = new Subject<string>();
  public success$ = this.success.asObservable();

  private info = new Subject<string>();
  public info$ = this.info.asObservable();

  private warn = new Subject<string>();
  public warn$ = this.warn.asObservable();

  emitError(message: string): void {
    this.error.next(message);
  }

  emitSuccess(message: string): void {
    this.success.next(message);
  }

  emitInfo(message: string): void {
    this.info.next(message);
  }

  emitWarning(message: string): void {
    this.warn.next(message);
  }
}
