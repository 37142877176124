import { Component } from '@angular/core';
import { AuthService } from 'src/app/core';
import { StringHelper } from 'src/app/core/helpers/string-helper';

@Component({
  selector: 'app-profile-preview',
  templateUrl: './profile-preview.component.html',
  styleUrls: ['./profile-preview.component.scss'],
})
export class ProfilePreviewComponent {
  constructor(private _auth: AuthService) {}

  get user() {
    return this._auth.storedUser;
  }

  get role(): string {
    const userRole = this.user.roleName;
    const updatedRole = userRole.replace('Admin', ' Admin');

    return StringHelper.firstLetterToUpperCase(updatedRole) || '';
  }

  get userName(): string {
    return `${this.user.firstName || ''} ${this.user.lastName || ''}`;
  }

  get shortUserName(): string {
    const firstLetter = (name: string) => StringHelper.firstLetter(name);
    const initials = [
      firstLetter(this.user.firstName),
      firstLetter(this.user.lastName),
    ];

    return initials.join('') || '-';
  }

  public logout() {
    this._auth.logout();
  }
}
