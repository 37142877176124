<div
  class="profile-preview d-flex-align-center"
  nz-dropdown
  nzTrigger="click"
  [nzDropdownMenu]="profileMenu"
>
  <nz-avatar
    [nzShape]="'square'"
    [ngStyle]="{ background: '#E6EEF5', color: '#03529F' }"
    [nzText]="shortUserName"
  ></nz-avatar>
</div>

<nz-dropdown-menu #profileMenu="nzDropdownMenu">
  <div class="preview-wrapper">
    <ul nz-menu>
      <div class="dropdown-user">
        <div class="dropdown-preview">
          <nz-avatar
            [nzShape]="'square'"
            [ngStyle]="{ background: '#E6EEF5', color: '#03529F' }"
            [nzText]="shortUserName"
            [nzSize]="60"
          ></nz-avatar>
          <div class="dropdown-preview_description">
            <strong class="dropdown-preview_name">{{ userName }}</strong>
            <span>{{ user.email || "" }}</span>
            <span>{{ role }}</span>
          </div>
        </div>
      </div>
      <li nz-menu-divider class="dropdown-divider"></li>
      <li nz-menu-item (click)="logout()">
        <img
          src="/assets/icons/signout.svg"
          width="24px"
          height="24px"
          alt="Sign Out icon"
        />
        {{ "profilePreview.signOut" | translate }}
      </li>
    </ul>
  </div>
</nz-dropdown-menu>
