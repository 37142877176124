import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export type Language = 'en';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private readonly LANG_STORAGE_KEY = 'lift_lang';

  private _currentLanguageSubject = new BehaviorSubject<Language>(
    this._getStoredLanguage()
  );

  public currentLanguage$ = this._currentLanguageSubject.asObservable();

  get language(): Language {
    return this._currentLanguageSubject.value;
  }

  public setLanguage(language: Language): void {
    this._storeLanguage(language);
  }

  private _getStoredLanguage(): Language {
    return localStorage.getItem(this.LANG_STORAGE_KEY) as Language;
  }

  private _storeLanguage(language: Language): void {
    localStorage.setItem(this.LANG_STORAGE_KEY, language);
  }
}
