import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormFieldComponent } from './form-field.component';
import { FormInputDirective } from './input.directive';
import { NzFormModule } from 'ng-zorro-antd/form';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [FormFieldComponent, FormInputDirective],
  imports: [CommonModule, NzFormModule, TranslateModule],
  exports: [FormFieldComponent, FormInputDirective],
})
export class FormFieldModule {}
