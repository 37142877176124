export class UserModel {
  constructor(
    public firstName: string,
    public lastName: string,
    public email: string,
    public localId: string,
    private _token: string,
    private role: string,
    private roleName: string,
    private blocked: boolean,
  ) {}
}