<section class="reset auth-bg">
  <app-spinner *ngIf="loading"></app-spinner>
  <img class="img-top" src="/assets/auth/confirmation/1.svg" alt="image" />
  <div class="reset-container">
    <div class="reset-wrapper">
      <img
        class="img-logo"
        src="/assets/auth/workplace-hover.svg"
        alt="logo-image"
      />
      <div class="reset-title">
        <h2>{{ "auth.reset.title" | translate }}</h2>
      </div>
      <form [formGroup]="form">
        <nz-form-item class="inline-field">
          <nz-form-label nzFor="password"
            >{{ "auth.reset.newPassword" | translate
            }}<span>*</span></nz-form-label
          >
          <app-form-field>
            <nz-input-group
              class="form-input-group"
              [nzSuffix]="passwordSuffix"
              [nzStatus]="passwordError"
            >
              <input
                nz-input
                formControlName="password"
                placeholder="{{ 'auth.enterPassword' | translate }}"
                oninput="this.value=this.value.trim()"
                [type]="passwordVisible ? 'text' : 'password'"
                appFormInput
                id="password"
              />
            </nz-input-group>
          </app-form-field>
        </nz-form-item>
        <nz-form-item class="inline-field">
          <nz-form-label nzFor="confirmPassword"
            >{{ "auth.confirmPassword" | translate
            }}<span>*</span></nz-form-label
          >
          <app-form-field>
            <nz-input-group
              class="form-input-group"
              [nzSuffix]="confirmPasswordSuffix"
              [nzStatus]="confirmError"
            >
              <input
                nz-input
                [type]="confirmPasswordVisible ? 'text' : 'password'"
                oninput="this.value=this.value.trim()"
                formControlName="confirm_password"
                placeholder="{{ 'auth.enterPassword' | translate }}"
                appFormInput
                id="confirmPassword"
              />
            </nz-input-group>
          </app-form-field>
        </nz-form-item>
      </form>
      <button (click)="submit()" nz-button>
        {{ "auth.reset.resetButton" | translate }}
      </button>
    </div>
  </div>
  <img class="img-bottom" src="/assets/auth/confirmation/2.svg" alt="image" />
</section>

<app-message></app-message>
<ng-template #passwordSuffix>
  <span nz-icon (click)="passwordVisible = !passwordVisible">
    <img
      [src]="
        passwordVisible ? '/assets/icons/open.svg' : '/assets/icons/hide.svg'
      "
      alt="show-password"
    />
  </span>
</ng-template>
<ng-template #confirmPasswordSuffix>
  <span nz-icon (click)="confirmPasswordVisible = !confirmPasswordVisible">
    <img
      [src]="
        confirmPasswordVisible
          ? '/assets/icons/open.svg'
          : '/assets/icons/hide.svg'
      "
      alt="show-password"
    />
  </span>
</ng-template>
