import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AuthService } from '../core/services/auth/auth.service';
import { first } from 'rxjs';
import { AuthResponse } from '../core/interfaces/auth.interfaces';
import { Router } from '@angular/router';
import { MessageService } from '../_shared/components/message';
import { environment } from 'src/environments/environment.dev';
import { AppRoutes } from '../app-routes';

@Component({
  selector: 'app-authorization',
  templateUrl: './authorization.component.html',
  styleUrls: ['./authorization.component.scss'],
})
export class AuthorizationComponent implements OnInit {
  public loginForm!: UntypedFormGroup;
  public passwordVisible = false;
  public isLoading!: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private auth: AuthService,
    private router: Router,
    private _message: MessageService,
  ) {}

  public ngOnInit(): void {
    const email = localStorage.getItem('email');

    this.loginForm = this.fb.group({
      email: [email || "", [Validators.required, Validators.pattern(environment.emailPattern)]],
      password: ["", [Validators.required]],
    });
  }

  public signIn(): void {
    if (this.loginForm.valid) {
      this.isLoading = true;
      const email = this.loginForm.value.email;
      const password = this.loginForm.value.password;

      this.signInUser(email, password);
    } else {
      this.validateForm();
    }
  }

  private signInUser(email: string, password: string): void {
    this.auth
      .signIn(email, password)
      .pipe(first())
      .subscribe({
        next: (resp: AuthResponse) => {
          this.isLoading = false;
          this.cleanSessionStorage();
          this.router.navigate([``]);
        },
        error: (errorMessage: string) => {
          this.handleSignInError(errorMessage);
        },
      });
  }

  private cleanSessionStorage() {
    const email = localStorage.getItem('email');
    if (email) {
      localStorage.removeItem('email');
    }
  }

  private validateForm(): void {
    Object.values(this.loginForm.controls).forEach((control) => {
      if (control.invalid) {
        control.markAsDirty();
        control.updateValueAndValidity({ onlySelf: true });
      }
    });
  }

  private handleSignInError(errorMessage: string): void {
    this.isLoading = false;
    this._message.emitError(errorMessage);
  }

  get passwordError() {
    if (
      this.loginForm.get('password')?.invalid &&
      this.loginForm.get('password')?.touched
    ) {
      return 'error';
    }
    return '';
  }

  public forgotPassword() {
    this.router.navigate([AppRoutes.FORGOT]);
  }
}
