import { Component, ContentChild, OnInit } from '@angular/core';
import { FormInputDirective } from './input.directive';
import { ERROR_MESSAGES } from './form-field-errors.config';
import { fadeAnimation } from '../../animations';
import { environment } from '../../../../environments/environment.dev';

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  animations: [fadeAnimation],
})
export class FormFieldComponent implements OnInit {
  @ContentChild(FormInputDirective, { static: true })
  public formInpuDirective!: FormInputDirective;

  get errorMessage(): { key: string; options: any } | null {
    const errors = Object.entries(
      this.formInpuDirective?.ngControl?.control?.errors || {},
    );
    const patternError = errors.find((el) => el.includes('pattern'));
    const maxlengthError = errors.find((el) => el.includes('maxlength'));
    const minlengthError = errors.find((el) => el.includes('minlength'));

    if (!errors.length || !this.isControlTouched) {
      return null;
    }

    if (maxlengthError || minlengthError) {
      const [key, value] = maxlengthError || minlengthError!;
      return {
        key: ERROR_MESSAGES[key],
        options: { length: value.requiredLength },
      };
    } else if (patternError && !(maxlengthError || minlengthError)) {
      if (patternError[1]?.requiredPattern === environment.emailPattern) {
        return {
          key: ERROR_MESSAGES['email'],
          options: patternError[1],
        };
      } else if (
        patternError[1]?.requiredPattern === environment.phonePattern
      ) {
        return {
          key: ERROR_MESSAGES['phone'],
          options: patternError[1],
        };
      } else if (patternError[1]?.requiredPattern === environment.numbersPattern) {
        return {
          key: ERROR_MESSAGES['numbers'],
          options: patternError[1],
        };
      }
    }

    const [key, value] = errors[0];

    return {
      key: ERROR_MESSAGES[key],
      options: value,
    };
  }

  get isControlTouched(): boolean | undefined {
    return this.formInpuDirective.ngControl.control?.touched;
  }

  public ngOnInit() {
    if (!this.formInpuDirective) {
      throw new Error('FormInputDirective is required!');
    }
  }
}
