<ng-template #notificationTemplate let-data="data">
  <div class="lc-notification__body">
    <ng-container *ngIf="data.type === notificationType.SUCCESS">
      <img src="/assets/icons/toastr-icons/done.svg" alt="Success icon" />
    </ng-container>
    <ng-container *ngIf="data.type === notificationType.ERROR">
      <img src="/assets/icons/toastr-icons/error.svg" alt="Error icon" />
    </ng-container>
    <ng-container *ngIf="data.type === notificationType.INFO">
      <img src="/assets/icons/toastr-icons/info.svg" alt="Info icon" />
    </ng-container>
    <ng-container *ngIf="data.type === notificationType.WARNING">
      <img src="/assets/icons/toastr-icons/warn.svg" alt="Warning icon" />
    </ng-container>
    <p class="lc-notification__text">{{ data.message }}</p>
  </div>
</ng-template>

<ng-template #notificationCloseSuccess>
  <img src="/assets/icons/toastr-icons/done-exit.svg" alt="Close icon" />
</ng-template>

<ng-template #notificationCloseInfo>
  <img src="/assets/icons/toastr-icons/info-exit.svg" alt="Close icon" />
</ng-template>

<ng-template #notificationCloseWarn>
  <img src="/assets/icons/toastr-icons/warn-exit.svg" alt="Close icon" />
</ng-template>

<ng-template #notificationCloseError>
  <img src="/assets/icons/toastr-icons/error-exit.svg" alt="Close icon" />
</ng-template>
