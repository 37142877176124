export abstract class StringHelper {
  /**
   * Returns the first letter of a given string.
   * @param string - The input string.
   * @returns The first letter of the input string, or an empty string if the input is undefined.
   */

  static firstLetter(string: string | undefined): string {
    return string ? string.charAt(0) : '';
  }

  /**
   * Converts the first letter of a string to uppercase.
   * @param string - The input string.
   * @returns The input string with the first letter converted to uppercase.
   */
  static firstLetterToUpperCase(string: string): string {
    return string.charAt(0).toLocaleUpperCase() + string.slice(1);
  }
}
