import { AfterViewChecked, ChangeDetectorRef, Component, TemplateRef, ViewChild, inject } from '@angular/core';
import { routerAnimation } from '../_shared/animations';

@Component({
  selector: 'app-super-admin-layout',
  templateUrl: './super-admin-layout.component.html',
  styleUrls: ['./super-admin-layout.component.scss'],
  animations: [routerAnimation]
})
export class SuperAdminLayoutComponent implements AfterViewChecked {
  private _changeRef: ChangeDetectorRef = inject(ChangeDetectorRef);

  public ngAfterViewChecked(): void {
    this._changeRef.detectChanges();
  }
}
