import { NgModule } from '@angular/core';
import { ProfilePreviewComponent } from './profile-preview.component';
import { CommonModule } from '@angular/common';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ProfilePreviewComponent],
  imports: [CommonModule, NzAvatarModule, NzDropDownModule, TranslateModule],
  exports: [ProfilePreviewComponent],
})
export class ProfilePreviewModule {}
