import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AuthorizationComponent } from "./authorization.component";
import { AuthorizationRoutingModule } from "./authorization-routing.module";
import { NzFormModule } from 'ng-zorro-antd/form';
import { ReactiveFormsModule } from "@angular/forms";
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { MessageModule } from "../_shared/components/message";
import { FormFieldModule } from "../_shared/components/form-field";
import { SpinnerComponent } from "../_shared/components/spinner/spinner.component";
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations:[
    AuthorizationComponent
  ],
  imports:[
    CommonModule,
    AuthorizationRoutingModule,
    ReactiveFormsModule,
    NzFormModule,
    NzInputModule,
    NzButtonModule,
    NzIconModule,
    NzCheckboxModule,
    MessageModule,
    TranslateModule,
    FormFieldModule,
    SpinnerComponent
  ],
  providers:[]
})

export class AuthorizationModule {}