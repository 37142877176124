import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.dev';
import { IClientAdmin, IUser } from '../../interfaces/IClientAdmin.interfaces';
import { Observable, Subject } from 'rxjs';
import { shakeParams } from '../../classes/http-params.handler';

@Injectable({
  providedIn: 'root',
})
export class ClientAdminService {
  private refetch = new Subject<void>();
  public refetch$ = this.refetch.asObservable();

  private search = new Subject<string>();
  public search$ = this.search.asObservable();

  constructor(private _http: HttpClient) {}

  public _addClientAdmin(user: IClientAdmin) {
    return this._http.post(`${environment.host}users`, user);
  }

  public _getClientAdminRegistration(token: string): Observable<IUser> {
    const headers = { Authorization: `Bearer ${token}` };
    return this._http.get<IUser>(`${environment.host}users/registration`, {
      headers,
    });
  }

  public verifyClientAdminRegistration(
    user: IUser,
    token: string,
  ): Observable<IUser> {
    const headers = { Authorization: `Bearer ${token}` };
    return this._http.post<IUser>(
      `${environment.host}users/registration`,
      user,
      {
        headers,
      },
    );
  }

  public getClients(params?: any): Observable<IClientAdmin[]> {
    const modifiedParams = params ? shakeParams(params) : params;
    return this._http.get<IClientAdmin[]>(`${environment.host}users/admins`, {
      params: modifiedParams,
    });
  }

  public deleteClientAdmin(id: string | undefined) {
    return this._http.delete(`${environment.host}users/${id}`);
  }

  public updateClientAdmin(id: string | undefined, user: IClientAdmin) {
    return this._http.put(`${environment.host}users/${id}`, user);
  }

  public managePremissions(id: string | undefined, user: IClientAdmin) {
    return this._http.put(`${environment.host}users/block/${id}`, user);
  }

  public refetchAdmins(): void {
    this.refetch.next();
  }

  public onSearch(search: string): void {
    this.search.next(search);
  }
}
