import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from './core/services/auth/auth.service';
import { EventService } from './_shared/services/event/event.service';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private eventSub!: Subscription;

  constructor(
    private translate: TranslateService,
    private auth: AuthService,
    private eventService: EventService,
  ) {
    translate.setDefaultLang('en');
    translate.use('en');
  }

  public ngOnInit(): void {
    this.eventSub = this.eventService.on('logout', () => {
      this.auth.logout();
    });
  }
  
  public ngOnDestroy(): void {
    this.eventSub.unsubscribe();
  }
}
