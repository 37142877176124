import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SignUpConfirmRoutingModule } from './sign-up-confirm-routing.module';
import { SignUpConfirmComponent } from './sign-up-confirm.component';
import { FooterComponent } from 'src/app/footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormFieldModule } from 'src/app/_shared/components/form-field';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { SpinnerComponent } from 'src/app/_shared/components/spinner/spinner.component';
import { MessageModule } from 'src/app/_shared/components/message';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [SignUpConfirmComponent],
  imports: [
    CommonModule,
    SignUpConfirmRoutingModule,
    FooterComponent,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    FormFieldModule,
    NzFormModule,
    NzGridModule,
    NzInputModule,
    NzButtonModule,
    FormFieldModule,
    NzIconModule,
    NzButtonModule,
    SpinnerComponent,
    MessageModule
  ],
})
export class SignUpConfirmModule {}
