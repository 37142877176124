<section class="forgot auth-bg">
  <app-spinner *ngIf="loading"></app-spinner>
  <img class="img-top" src="/assets/auth/confirmation/1.svg" alt="image" />
  <div class="forgot-container">
    <div class="forgot-wrapper">
      <img
        class="img-logo"
        src="/assets/auth/workplace-hover.svg"
        alt="logo-image"
      />
      <div class="forgot-title">
        <h2>{{ "auth.forgot.title" | translate }}</h2>
        <p>{{ "auth.forgot.description" | translate }}</p>
      </div>
      <form [formGroup]="form">
        <nz-form-item class="inline-field">
          <nz-form-label nzFor="Email"
            >{{ "auth.email" | translate }}<span>*</span></nz-form-label
          >
          <app-form-field>
            <input
              nz-input
              formControlName="email"
              placeholder="{{ 'auth.emailPlaceholder' | translate }}"
              class="form-input"
              appFormInput
              id="email"
              [disabled]="true"
            />
          </app-form-field>
        </nz-form-item>
      </form>
      <button (click)="submit()" nz-button>
        {{ "auth.forgot.resetButton" | translate }}
      </button>
      <div class="forgot-back">
        {{ "auth.forgot.knowPassword" | translate }}
        <a (click)="onSignInClick()">{{ "auth.signIn" | translate }}</a>
      </div>
    </div>
  </div>
  <img class="img-bottom" src="/assets/auth/confirmation/2.svg" alt="image" />
</section>

<app-message></app-message>
