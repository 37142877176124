import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/services/auth/auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppRoutes } from 'src/app/app-routes';
import { StringHelper } from 'src/app/core/helpers/string-helper';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
@UntilDestroy()
export class HeaderComponent {
  public appRoutes = AppRoutes;

  constructor(private auth: AuthService) {}

  public logout() {
    this.auth.logout();
  }

  get user() {
    return this.auth.storedUser;
  }

  get userName() {
    return `${StringHelper.firstLetterToUpperCase(this.user.firstName) || ''} ${
      StringHelper.firstLetterToUpperCase(this.user.lastName) || ''
    }`;
  }
}
