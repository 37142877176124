import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import { MessageService } from './message.service';
import {
  NzNotificationDataOptions,
  NzNotificationService,
} from 'ng-zorro-antd/notification';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { notificationConfig } from '../../configs/notification';

export enum ENotificationType {
  SUCCESS,
  ERROR,
  INFO,
  WARNING,
}

@UntilDestroy()
@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent implements OnInit {
  @ViewChild('notificationTemplate')
  notificationTemplate!: TemplateRef<object>;

  @ViewChild('notificationCloseSuccess')
  notificationCloseSuccess!: TemplateRef<void>;

  @ViewChild('notificationCloseInfo')
  notificationCloseInfo!: TemplateRef<void>;

  @ViewChild('notificationCloseWarn')
  notificationCloseWarn!: TemplateRef<void>;

  @ViewChild('notificationCloseError')
  notificationCloseError!: TemplateRef<void>;

  constructor(
    private _messageService: MessageService,
    private _notificationService: NzNotificationService,
  ) {}

  public notificationType = ENotificationType;

  public ngOnInit(): void {
    this._messageService.success$.pipe(untilDestroyed(this)).subscribe({
      next: (message: string) => {
        this.showSuccess(message);
      },
    });

    this._messageService.error$.pipe(untilDestroyed(this)).subscribe({
      next: (message: string) => {
        this.showError(message);
      },
    });

    this._messageService.info$.pipe(untilDestroyed(this)).subscribe({
      next: (message: string) => {
        this.showInfo(message);
      },
    });

    this._messageService.warn$.pipe(untilDestroyed(this)).subscribe({
      next: (message: string) => {
        this.showWarning(message);
      },
    });
  }

  public showError(message: string): void {
    this._notificationService.template(
      this.notificationTemplate,
      this._getErrorConfig(message),
    );
  }

  public showSuccess(message: string): void {
    this._notificationService.template(
      this.notificationTemplate,
      this._getSuccessConfig(message),
    );
  }

  public showInfo(message: string): void {
    this._notificationService.template(
      this.notificationTemplate,
      this._getInfoConfig(message),
    );
  }

  public showWarning(message: string): void {
    this._notificationService.template(
      this.notificationTemplate,
      this._getWarningConfig(message),
    );
  }

  private _getSuccessConfig(message: string): NzNotificationDataOptions {
    return {
      ...notificationConfig,
      nzClass: 'lc-notification success',
      nzCloseIcon: this.notificationCloseSuccess,
      nzData: {
        title: 'Success Message',
        message: `${message}`,
        type: ENotificationType.SUCCESS,
      },
    } as NzNotificationDataOptions;
  }

  private _getErrorConfig(message: string): NzNotificationDataOptions {
    return {
      ...notificationConfig,
      nzClass: 'lc-notification danger',
      nzCloseIcon: this.notificationCloseError,
      nzData: {
        title: 'Error Message',
        message: `${message}`,
        type: ENotificationType.ERROR,
      },
    } as NzNotificationDataOptions;
  }

  private _getInfoConfig(message: string): NzNotificationDataOptions {
    return {
      ...notificationConfig,
      nzClass: 'lc-notification info',
      nzCloseIcon: this.notificationCloseInfo,
      nzData: {
        title: 'Info Message',
        message: `${message}`,
        type: ENotificationType.INFO,
      },
    } as NzNotificationDataOptions;
  }

  private _getWarningConfig(message: string): NzNotificationDataOptions {
    return {
      ...notificationConfig,
      nzClass: 'lc-notification warn',
      nzCloseIcon: this.notificationCloseWarn,
      nzData: {
        title: 'Warning Message',
        message: `${message}`,
        type: ENotificationType.WARNING,
      },
    } as NzNotificationDataOptions;
  }
}
