import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export function eMatchValidator(
  controlName: string,
  matchingControlName: string,
): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const passwordControl = formGroup.get(controlName);
    const confirmPasswordControl = formGroup.get(matchingControlName);

    if (!passwordControl || !confirmPasswordControl) {
      return null;
    }

    if (passwordControl.value !== confirmPasswordControl.value) {
      confirmPasswordControl.setErrors({ matching: false });
    } else {
      confirmPasswordControl.setErrors(null);
    }

    return null;
  };
}
