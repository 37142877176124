export enum AppRoutes {
  DASHBOARD = 'dashboard',
  CLIENT_ADMINS = 'clientadmins',
  CLIENTS = 'clients',
  BUILDINGS = 'builgings',
  LIFTS = 'lifts',
  DEVICES = 'devices',
  INSTALLERS = 'installers',
  AUTH = 'auth',
  FORGOT = 'forgot',
  RESET = 'reset',
  VERIFY = 'verify',
}
