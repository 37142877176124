/* eslint-disable no-prototype-builtins */
import { Directive, OnInit, inject } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MessageService } from '../components/message';
import { TranslateService } from '@ngx-translate/core';

export interface IErrorHandler {
  getError(key: string, errors: any): string;
}

/**
 * A class that implements the IErrorHandler interface and provides a method to get error messages
 * with a translated suffix and error key.
 */
export class TranslateErrorHandler implements IErrorHandler {
  constructor(private _suffix: string) {}

  /**
   * Returns an error message with a translated suffix and error key.
   * @param key - The key of the error message.
   * @param errors - The errors object.
   * @returns The error message with a translated suffix and error key.
   */
  getError(key: string, errors: any): string {
    const errorKey = Object.keys(errors)[0];

    return `${this._suffix ? `${this._suffix}.` : ''}${key}.${errorKey}`;
  }
}

@Directive()
/**
 * An abstract class that provides a base implementation for form components.
 * @template Controls The type of controls in the form.
 */
export abstract class FormComponent<Controls = any> implements OnInit {
  protected _notifier: MessageService = inject(MessageService);
  protected _translate: TranslateService = inject(TranslateService);

  public form!: FormGroup;
  public errorHandler: IErrorHandler = new TranslateErrorHandler('errors');
  public errors: { [P in keyof Controls]?: string } = {};

  protected abstract createForm(): FormGroup;

  /**
   * Returns an object containing all the form controls as `AbstractControl` instances.
   * The keys of the object correspond to the names of the controls.
   */
  get controls(): { [key in keyof Partial<Controls>]: AbstractControl } {
    return (
      this.form &&
      (this.form.controls as {
        [key in keyof Partial<Controls>]: AbstractControl;
      })
    );
  }

  public ngOnInit(): void {
    this.form = this.createForm();
  }

  /**
   * Applies the changes made to the form and handles the submission if the form is valid.
   * If the form is invalid, it handles the invalid form.
   */
  public apply(): void {
    this._validateForm();
    if (this.form.invalid) {
      this._handleFormInvalid();
      return;
    }

    this._handleSubmit();
  }

  /**
   * Handles the case where the form is invalid by showing an error message.
   */
  protected _handleFormInvalid(): void {
    this._showErrorMessage();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected _handleSubmit(): void {}

  /**
   * Validates all the form controls and updates their validity status.
   * Also updates the validity status of the form itself and marks all controls as touched.
   * Finally, sets the errors for the form controls.
   */
  protected _validateForm() {
    for (const key in this.controls)
      if (this.controls.hasOwnProperty(key)) {
        const control = this.controls[key];
        control.markAsTouched({ onlySelf: true });
        control.updateValueAndValidity({ onlySelf: true, emitEvent: false });
      }

    this.form.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    this.form.markAllAsTouched();
    this._setErrors();
  }

  /**
   * Sets the errors for the form component.
   * @protected
   * @returns void
   */
  protected _setErrors() {
    const errors: { [key in keyof Controls]?: string } = {};

    // eslint-disable-next-line no-prototype-builtins
    for (const key in this.controls)
      if (this.controls.hasOwnProperty(key)) {
        const control = this.controls[key];
        errors[key] =
          (control.dirty || control.touched) && control.invalid
            ? this.errorHandler.getError(key, control.errors)
            : '';
      }

    this.errors = errors;
  }

  /**
   * Shows the first error message in the `errors` object by emitting an error notification.
   * If there are no errors, nothing happens.
   */
  protected _showErrorMessage(): void {
    const firstError = (this.errors as any)[
      Object.keys(this.errors).filter((key) => !!(this.errors as any)[key])[0]
    ];
    if (firstError) {
      this._notifier.emitError(firstError);
    }
  }

  /**
   * Creates a new FormData object and appends all form values to it.
   * @returns A new FormData object containing all form values.
   */
  protected _createFormData(): any {
    const formData: any = {};

    for (const [key, value] of Object.entries(this.form.value)) {
      if (
        Array.isArray(value) ||
        typeof value === 'boolean' ||
        value === null
      ) {
        formData[key] = value;
      } else {
        formData[key] = String(value).trim();
      }
    }

    return formData;
  }
}
