import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { AppRoutes } from 'src/app/app-routes';
import { AuthService } from '../services/auth/auth.service';
import { UserRole } from '../enums';

export const AuthGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const _auth = inject(AuthService);
  const _router = inject(Router);
  const _redirectTo = `/${AppRoutes.AUTH}`;
  const role: UserRole = _auth.storedUser.role;
  const roleToRouteMap = {
    [UserRole.SUPER_ADMIN]: `/superadmin/${AppRoutes.DASHBOARD}`,
    [UserRole.CLIENT_ADMIN]: `/admin/${AppRoutes.DASHBOARD}`,
    [UserRole.CLIENT]: `/client/${AppRoutes.DASHBOARD}`,
  };

  if (_auth.storedUser && !_auth.storedUser.blocked) {
    const allowedRoles = route.data?.['roles'] as UserRole[];

    if (allowedRoles && allowedRoles.includes(role)) {
      return true;
    } 
  }
  const redirectTo = roleToRouteMap[role] || _redirectTo;
  return _router.parseUrl(redirectTo);
};
