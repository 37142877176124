<section class="sign-in auth-bg">
  <img class="img-top" src="/assets/auth/confirmation/1.svg" alt="image" />
  <div class="sign-in__container">
    <div class="sign-in__wrapper">
      <form
        nz-form
        class="sign-in-form"
        [formGroup]="loginForm"
        (ngSubmit)="signIn()"
      >
        <div class="sign-in-form__img">
          <img
            src="../../assets/auth/workplace-hover.svg"
            width="250"
            height="100"
            alt="Logo"
          />
        </div>
        <div class="sign-in-form__wrapper">
          <h2 class="title">{{ "auth.title" | translate }}</h2>

          <nz-form-item class="inline-field">
            <nz-form-label nzFor="Email"
              >{{ "auth.email" | translate }}<span>*</span></nz-form-label
            >
            <app-form-field>
              <input
                nz-input
                formControlName="email"
                placeholder="Enter email"
                class="form-input"
                appFormInput
                id="email"
              />
            </app-form-field>
          </nz-form-item>

          <nz-form-item class="inline-field">
            <nz-form-label nzFor="password"
              >{{ "auth.password" | translate }}<span>*</span></nz-form-label
            >
            <app-form-field>
              <nz-input-group
                class="form-input-group"
                [nzSuffix]="passwordSuffix"
                [nzStatus]="passwordError"
              >
                <input
                  nz-input
                  formControlName="password"
                  oninput="this.value=this.value.trim()"
                  placeholder="Enter password"
                  [type]="passwordVisible ? 'text' : 'password'"
                  appFormInput
                  id="password"
                />
              </nz-input-group>
            </app-form-field>
          </nz-form-item>

          <div class="sign-in-form__forgot">
            <a (click)="forgotPassword()">{{
              "auth.forgotPassword" | translate
            }}</a>
          </div>

          <button
            class="sign-in-form__button-login"
            [ngClass]="{
              active: loginForm.value.email || loginForm.value.password
            }"
            [disabled]="isLoading"
          >
            {{ "auth.signIn" | translate }}
          </button>
        </div>
      </form>
      <app-spinner *ngIf="isLoading"></app-spinner>
    </div>
  </div>
  <img class="img-bottom" src="/assets/auth/confirmation/2.svg" alt="image" />
</section>

<app-message></app-message>

<ng-template #passwordSuffix>
  <span nz-icon (click)="passwordVisible = !passwordVisible">
    <img
      [src]="
        passwordVisible ? '/assets/icons/open.svg' : '/assets/icons/hide.svg'
      "
      alt="eye-icon"
    />
  </span>
</ng-template>
