import { AfterViewChecked, ChangeDetectorRef, Component, inject } from '@angular/core';
import { routerAnimation } from '../_shared/animations';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  animations: [routerAnimation]
})
export class LayoutComponent implements AfterViewChecked {
  private _changeRef: ChangeDetectorRef = inject(ChangeDetectorRef);

  public ngAfterViewChecked(): void {
    this._changeRef.detectChanges();
  }
}
