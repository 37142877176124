import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IUser } from '../../interfaces/IClientAdmin.interfaces';
import { environment } from 'src/environments/environment.dev';
import { Observable, Subject } from 'rxjs';
import { IClient } from '../../interfaces/IClient.interface';
import { shakeParams } from '../../classes/http-params.handler';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  private _refetch = new Subject<void>();
  public refetch$ = this._refetch.asObservable();

  private _search = new Subject<string>();
  public search$ = this._search.asObservable();

  constructor(private _http: HttpClient) {}

  public addClient(user: IUser) {
    return this._http.post(`${environment.host}users/clients`, user);
  }
  
  public editClient(id: string | undefined, user: IUser) {
    return this._http.put(`${environment.host}users/clients/${id}`, user);
  }

  public deleteClient(id: string | undefined) {
    return this._http.delete(`${environment.host}users/clients/${id}`);
  }

  public getAllClients(params?: any): Observable<IClient[]> {
    const modifiedParams = params ? shakeParams(params) : params;
    return this._http.get<IClient[]>(`${environment.host}users/clients`, {
      params: modifiedParams,
    });
  }

  public getAdminClients(
    id: string | undefined,
    params?: any,
  ): Observable<IClient[]> {
    const modifiedParams = params ? shakeParams(params) : params;
    return this._http.get<IClient[]>(`${environment.host}users/admins/clients/${id}`, {
      params: modifiedParams,
    });
  }

  public refetchClients() {
    this._refetch.next();
  }

  public onSearch(search: string): void {
    this._search.next(search);
  }
}
